<template>
    <div class="container-fluid">
        <div class="text-center mt-4 mb-3 font-weight-bold" style="font-size: 24px;">
            <span>ลงทะเบียนผู้ใช้</span>
        </div>
        <b-row>
            <b-col sm="0" md="1" lg="2" xl="2"></b-col>
            <b-col sm="12" md="10" lg="8" xl="8">
                <div class="main-form-oscc">
                    <b-row>
                        <b-col class="header-form-oscc mr-3 ml-3">
                            ข้อมูลผู้ใช้
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input1"
                                    class="mb-0"
                            >
                                <template #label>
                                    รหัสหน่วยงาน (5 หลัก)
                                </template>
                                <b-form-input
                                        id="input1"
                                        v-model="register.agency_code"
                                        :maxlength="5"
                                        class="input-form-oscc"
                                        placeholder="ระบุรหัสหน่วยงาน"
                                        readonly
                                        type="text"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input2"
                                    class="mb-0"
                            >
                                <template #label>
                                    ชื่อหน่วยงาน
                                </template>
                                <b-form-input
                                        id="input2"
                                        v-model="register.agency_name"
                                        readonly
                                        class="input-form-oscc"
                                        placeholder="ระบุชื่อหน่วยงาน"
                                        type="text"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
<!--                    <b-row>-->
<!--                        <b-col cols="6" class="body-form-oscc">-->
<!--                            <b-form-group-->
<!--                                    label-for="input3"-->
<!--                                    class="mb-0"-->
<!--                            >-->
<!--                                <template #label>-->
<!--                                    ชื่อผู้ใช้-->
<!--                                </template>-->
<!--                                <b-form-input-->
<!--                                        id="input3"-->
<!--                                        v-model="register.name"-->
<!--                                        class="input-form-oscc"-->
<!--                                        placeholder="ระบุชื่อผู้ใช้"-->
<!--                                        readonly-->
<!--                                        type="text"-->
<!--                                ></b-form-input>-->
<!--                            </b-form-group>-->
<!--                        </b-col>-->
<!--                        <b-col cols="6" class="body-form-oscc">-->

<!--                        </b-col>-->
<!--                    </b-row>-->
                    <b-row>
                        <b-col cols="6" class="body-form-oscc pt-0">
                            <b-form-group
                                    label-for="input6"
                                    class="mb-0"
                            >
                                <template #label>
                                    อีเมล
                                </template>
                                <b-form-input
                                        id="input6"
                                        v-model="register.email"
                                        class="input-form-oscc"
                                        placeholder="ระบุอีเมล"
                                        readonly
                                        type="email"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" class="body-form-oscc pt-0">
                            <b-form-group
                                    label-for="input7"
                                    class="mb-0"
                            >
                                <template #label>
                                    เลขบัตรประจำตัวประชาชน
                                </template>
                                <b-form-input
                                        id="input7"
                                        v-model="register.id_card"
                                        class="input-form-oscc"
                                        placeholder="ระบุเลขบัตรประจำตัวประชาชน"
                                        readonly
                                        type="text"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row style="margin-bottom: 25px">
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input8"
                                    class="mb-0"
                            >
                                <template #label>
                                    ตำแหน่ง
                                </template>
                                <b-form-input
                                        id="input8"
                                        v-model="register.position"
                                        class="input-form-oscc"
                                        placeholder="ระบุตำแหน่ง"
                                        readonly
                                        type="email"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input9"
                                    class="mb-0"
                            >
                                <template #label>
                                    แผนก
                                </template>
                                <b-form-input
                                        id="input9"
                                        v-model="register.department"
                                        class="input-form-oscc"
                                        placeholder="ระบุแผนก"
                                        readonly
                                        type="text"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="header-form-oscc mr-3 ml-3">
                            รายละเอียดอื่น ๆ
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input10"
                                    class="mb-0"
                            >
                                <template #label>
                                    คำนำหน้าชื่อ
                                </template>
                                <b-form-input
                                        id="input10"
                                        v-model="register.title"
                                        class="input-form-oscc"
                                        readonly
                                        type="text"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input11"
                                    class="mb-0"
                            >
                                <template #label>
                                    ชื่อ-สกุล
                                </template>
                                <b-form-input
                                        id="input11"
                                        v-model="register.full_name"
                                        class="input-form-oscc"
                                        readonly
                                        type="text"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input12"
                                    class="mb-0"
                            >
                                <template #label>
                                    คำนำหน้าชื่อ (อังกฤษ)
                                </template>
                                <b-form-input
                                        id="input12"
                                        v-model="register.title_eng"
                                        class="input-form-oscc"
                                        readonly
                                        type="text"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input13"
                                    class="mb-0"
                            >
                                <template #label>
                                    ชื่อ-สกุล (อังกฤษ)
                                </template>
                                <b-form-input
                                        id="input13"
                                        v-model="register.full_name_eng"
                                        class="input-form-oscc"
                                        readonly
                                        type="text"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input14"
                                    class="mb-0"
                            >
                                <template #label>
                                    วัน/เดือน/ปีเกิด
                                </template>
                                <b-form-input
                                        id="input14"
                                        v-model="register.birth_date"
                                        class="input-form-oscc"
                                        readonly
                                        type="text"
                                ></b-form-input>
<!--                                <date-picker class="w-100" id="input14" v-model="register.birth_date" placeholder="ระบุวัน/เดือน/ปีเกิด" value-type="format" format="DD-MM-YYYY" :editable="false" :clearable="false"></date-picker>-->
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input15"
                                    class="mb-0"
                            >
                                <template #label>
                                    ที่อยู่
                                </template>
                                <b-form-input
                                        id="input15"
                                        v-model="register.address"
                                        class="input-form-oscc"
                                        placeholder="ระบุที่อยู่"
                                        readonly
                                        type="text"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row style="margin-bottom: 25px">
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input16"
                                    class="mb-0"
                            >
                                <template #label>
                                    เบอร์โทรศัพท์
                                </template>
                                <b-form-input
                                        id="input16"
                                        v-model="register.home_phone"
                                        class="input-form-oscc"
                                        placeholder="ระบุเบอร์โทรศัพท์"
                                        :maxlength="15"
                                        readonly
                                        type="tel"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input17"
                                    class="mb-0"
                            >
                                <template #label>
                                    เบอร์โทรศัพท์มือถือ
                                </template>
                                <b-form-input
                                        id="input17"
                                        v-model="register.mobile_phone"
                                        class="input-form-oscc"
                                        placeholder="ระบุเบอร์โทรศัพท์มือถือ"
                                        :maxlength="15"
                                        readonly
                                        type="tel"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col sm="0" md="1" lg="2" xl="2"></b-col>
        </b-row>
        <b-row class="mt-5">
            <b-col sm="0" md="1" lg="2" xl="2"></b-col>
            <b-col sm="12" md="10" lg="8" xl="8">
                <div class="main-form-oscc">
                    <b-row>
                        <b-col class="header-form-oscc mr-3 ml-3">
                            ยืนยันตัวตน
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input19"
                                    class="mb-0"
                            >
                                <template #label>
                                    รูปภาพบัตรประจำตัวประชาชนด้านหน้า
                                </template>
                                <div class="image-preview-oscc">
                                    <b-img style="width: 100%;height: auto;max-height: 100%" :src="src">

                                    </b-img>
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col sm="0" md="1" lg="2" xl="2"></b-col>
        </b-row>
        <b-row class="mt-5">
            <b-col sm="0" md="1" lg="2" xl="2"></b-col>
            <b-col sm="12" md="10" lg="8" xl="8">
                <b-button class="btn-oscc" variant="outline-secondary" @click="showAlert">กลับ</b-button>
            </b-col>
            <b-col sm="0" md="1" lg="2" xl="2"></b-col>
        </b-row>
        <b-row class="footer-oscc"></b-row>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import moment from "moment";
    import {domain} from "../util/Constants";
    export default {
        name: "register-form",
        components: {  },
        data: function () {
            return {
                src : '',
            }
        },
        created() {
            let test = moment(this.register.birth_date,'Y-M-D').format('D-M-Y')
            this.register.birth_date = test;
            this.src = domain.slice(0,-4)+'/images/register/'+this.register.image;
        },
        computed: {
            ...mapState({
                register: state => {
                    return state.Auth.user;
                }
            }),
        },
        methods:{
            showLoading(){
                this.$loading.show({background:
                        this.$swal.fire({
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            this.$swal.showLoading()
                        }
                    })
                    ,animation : true});
            },
            hideLoading(){
                this.$loading.hide();
                this.$swal.hideLoading()
            },
            showAlert(){
                this.$router.push('/')
            }
        }
    }
</script>

<style scoped>
    .main-form-oscc{
        background: #FFFFFF;
        border: 0.5px solid #A8A8A8;
        box-sizing: border-box;
        box-shadow: 1px 1px 9px 1px rgba(174, 194, 217, 0.3);
        border-radius: 5px;
    }
    .header-form-oscc{
        background: #DEE5FB;
        border-radius: 5px 5px 0 0;
        font-size: 18px;
        padding-top: 5px;
        padding-bottom: 2px;
    }
    .body-form-oscc{
        padding-left: 7%;
        padding-right: 6%;
        margin-top: 13px;
    }
    .star-red{
        color: red;
    }
    .input-form-oscc{
        background: #FFFFFF;
        border: 1px solid #7F98B5;
        box-sizing: border-box;
        border-radius: 20px;
    }
    input.input-mx {
        border-radius: 20px;
    }
    .image-upload-oscc{
        width: 100%;
        height: 240px;
        border: 1px solid #6392EC;
        box-sizing: border-box;
        border-radius: 5px;
        cursor: pointer;
    }
    .image-preview-oscc{
        width: 100%;
        height: 240px;
        border: 1px solid #6392EC;
        box-sizing: border-box;
        border-radius: 5px;
    }
    .icon-upload-oscc{
        position:relative;
        top:30%;
        left:50%;
        transform:translate(-50%, -50%);
    }
    .footer-oscc{
        margin-bottom: 5%;
    }
    .btn-oscc{
        font-size: 16px;
        box-shadow: 1px 1px 10px rgba(56, 84, 138, 0.28);
        border-radius: 24px;
        padding: 10px 50px 10px 50px;
    }
    .btn-submit-oscc{
        font-size: 16px;
        box-shadow: 1px 1px 10px rgba(56, 84, 138, 0.28);
        border-radius: 24px;
        padding: 10px 100px 10px 100px;
    }
    b-col{
        margin-bottom: 20px;
    }
</style>
